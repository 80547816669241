import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import Header from "../../components/Header";
import Menu from "../../components/Menu";
import FormAlterarSenha from "../../components/FormAlterarSenha";

const PageAlterarSenhaForm = () => {

  return (
    <React.Fragment>
      <Header />
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Menu active="" />
          </Grid>
          <Grid item xs={9} sx={{ mt: 1 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Alterar Senha
            </Typography>
            <Box sx={{ pr: 5 }}>
              <FormAlterarSenha />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default PageAlterarSenhaForm;
