import React from "react";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function BtnLimparUpload(params) {
  if (params.file.length === 0) {
    return "";
  }

  function handleBtnLimparClick(e) {
    e.preventDefault();
    document.querySelectorAll("[name=image]")[0].value = "";
    params.setFile([]);
  }
  return (
    <IconButton aria-label="delete" onClick={handleBtnLimparClick}>
      <DeleteIcon />
    </IconButton>
  );
}

export default BtnLimparUpload;
