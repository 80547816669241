import React from "react";
import { useParams } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import Header from "../../components/Header";
import Menu from "../../components/Menu";
import FormNovidades from "../../components/FormNovidades";

const PageNovidadesForm = () => {
  let params = useParams("id");

  return (
    <React.Fragment>
      <Header />
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Menu active="novidades" />
          </Grid>
          <Grid item xs={9} sx={{ mt: 1 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {params.id ? "Editar" : "Adicionar"} Novidade
            </Typography>
            <Box sx={{ pr: 5 }}>
              <FormNovidades id={params.id} listURL="/novidades" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default PageNovidadesForm;
