import React, { useEffect, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { apiGet } from "../../api";

const GridUsuarios = (params) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    apiGet("/usuarios").then((response) => {
      if (response && response.data) {
        response.data.forEach( (el,i) => {
          if( el.Empresa !== null )
          {
            response.data[i]["empresa_nome"] = el.Empresa.nome;
          }
          else 
          {
            response.data[i]["empresa_nome"] = "";
          }
          
        })
        setRows(response.data);
      }
    });
  }, []);

  const columns = [
    { field: "name", headerName: "Nome", width: 200 },
    { field: "email", headerName: "E-mail", width: 220 },
    { field: "empresa_nome", headerName: "Empresa", width: 140 },
    { field: "role", headerName: "Perfil", width: 80 },
    { field: "status", headerName: "Status", width: 150 },
  ];

  return (
    <div style={{ height: 550, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        disableMultipleSelection={true}
        onSelectionModelChange={params.handleCellSelected}
      />
    </div>
  );
};

export default GridUsuarios;
