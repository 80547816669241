import React from "react";

import apiURL from "../../apiURL";

function FileImagePreview(params) {
  if (params.imageKey === "" || params.imageKey === null) {
    return "";
  }
  return (
    <img
      src={`${apiURL}/novidades/banner/${params.imageKey}`}
      height="50"
      alt=""
      style={{ marginTop: "10px" }}
    />
  );
}

export default FileImagePreview;
