import React from 'react';
import Button from "@mui/material/Button";
import { apiDelete } from "../../api";

function BtnRemoverArquivo(params)
{
    if (params.fileKey === "" || params.fileKey === null) {
        return "";
      }
      function handleRemoverClick(e) {
        e.preventDefault();
  
        if( !window.confirm("deseja realmente excluir?\na página será recarregada") )
        {
          return;
        }
        apiDelete(params.deleteURI).then((response) => {
            if( response && response.status === 200 )
            {
                window.location.reload();
            }
            else 
            {
                alert("falha ao excluir");
            }
         
        });
      }
      return (
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={handleRemoverClick}
          
          sx={{ mt: 1 }}
        >
          Remover arquivo
        </Button>
      );
}

export default BtnRemoverArquivo;