import React, { useState } from "react";
import { apiPut } from "../../api";

import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import Typography from "@mui/material/Typography";
import brand from "../../assets/img/brand.svg";

function PagePassTempForm() {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPass2, setNewPass2] = useState("");
  const [fieldSenhaErro, setFieldSenhaErro] = useState(false);
  const [fieldSenhaErroHelp, setFieldSenhaErroHelp] = useState("");
  const [fieldNewPassErro, setFieldNewPassErro] = useState(false);
  const [fieldNewPassErroHelp, setFieldNewPassErroHelp] = useState("");
  const [fieldNewPass2Erro, setFieldNewPass2Erro] = useState(false);
  const [fieldNewPass2ErroHelp, setFieldNewPass2ErroHelp] = useState("");
  const [btnEntrarDisabled, setBtnEntrarDisabled] = useState(false);
  const [loginResultMessage, setLoginResultMessage] = useState();
  const [loginResultDisplay, setLoginResultDisplay] = useState("none");

  function handleSubmit(e) {
    e.preventDefault();
    setFieldNewPass2Erro(false);
    setFieldNewPass2ErroHelp("");

    if (!senhaActiveValidation() || !novaSenhaActiveValidation()) {
      return;
    }

    if (newPass !== newPass2) {
      setFieldNewPass2Erro(true);
      setFieldNewPass2ErroHelp("Senha repetida incorretamente");
      return;
    }

    setBtnEntrarDisabled(true);

    apiPut(
      "/usuarios/update-temp-pass",
      {
        email: email,
        pass: pass,
        newPass: newPass,
      },
      updateTempPassError
    ).then((response) => {
      setBtnEntrarDisabled(false);
      if (response && response.status === 200) {
         window.location.href = "/auth";
      }
    });
  }

  function updateTempPassError(error) {
    console.log(error.response.status);
    if (error.response.status === 403) {
      setLoginResultDisplay("");
      setLoginResultMessage("Login/senha incorretos ou usuário desativado");
    } else {
      setLoginResultDisplay("");
      setLoginResultMessage(
        "Houve um falha, a senha temporária não foi atualizada"
      );
    }
  }

  function senhaActiveValidation() {
    if (pass.length < 4 || pass.length > 20) {
      setFieldSenhaErro(true);
      setFieldSenhaErroHelp("Senha deve ter entre 4 e 20 caracteres");
      return false;
    } else {
      setFieldSenhaErro(false);
      setFieldSenhaErroHelp("");
      return true;
    }
  }

  function novaSenhaActiveValidation() {
    if (newPass.length < 4 || newPass.length > 20) {
      setFieldNewPassErro(true);
      setFieldNewPassErroHelp("Senha deve ter entre 4 e 20 caracteres");
      return false;
    } else {
      setFieldNewPassErro(false);
      setFieldNewPassErroHelp("");
      return true;
    }
  }

  return (
    <Container maxWidth="sm">
      <Paper variant="outlined" sx={{ p: 3, mt: 5 }}>
        <Box sx={{ textAlign: "center" }}>
          <img src={brand} width="150" alt="Kingshipline" />
        </Box>
        <Typography
          variant="h6"
          component="h1"
          sx={{ textAlign: "center", mb: 2, mt: 1, color: "primary.main" }}
        >
          HUB Admin
        </Typography>

        <Typography
          variant="h6"
          component="h1"
          sx={{ textAlign: "center", mb: 2, mt: 1, color: "text.secondary" }}
        >
          Atualize a senha temporária
        </Typography>

        <Alert severity="error" sx={{ mb: 2, display: loginResultDisplay }}>
          {loginResultMessage}
        </Alert>

        <form action="" autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            required
            id="email"
            label="E-mail"
            type="email"
            value={email}
            autoFocus={true}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            required
            type="password"
            id="pass"
            label="Senha"
            sx={{ mt: 2 }}
            error={fieldSenhaErro}
            helperText={fieldSenhaErroHelp}
            value={pass}
            onChange={(e) => {
              setPass(e.target.value);
            }}
            onKeyUp={senhaActiveValidation}
          />
          <TextField
            fullWidth
            required
            type="password"
            id="newPass"
            label="Nova Senha"
            error={fieldNewPassErro}
            helperText={fieldNewPassErroHelp}
            sx={{ mt: 2 }}
            value={newPass}
            onChange={(e) => {
              setNewPass(e.target.value);
            }}
            onKeyUp={novaSenhaActiveValidation}
          />
          <TextField
            fullWidth
            required
            type="password"
            id="newPass2"
            label="Repita Nova Senha"
            error={fieldNewPass2Erro}
            helperText={fieldNewPass2ErroHelp}
            sx={{ mt: 2 }}
            value={newPass2}
            onChange={(e) => {
              setNewPass2(e.target.value);
            }}
          />
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
            disabled={btnEntrarDisabled}
          >
            Entrar
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default PagePassTempForm;
