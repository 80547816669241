import React from 'react';
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const FormBtnVoltar = (params) => 
{
    return (<Link to={params.href} style={{ textDecoration: "none" }}>
    <Button variant="text">Voltar</Button>
  </Link>)
}

export default FormBtnVoltar;