import React, { useEffect, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { apiGet } from "../../api";

import apiURL from "../../apiURL";

const GridNovidades = (params) => { 

    const [rows, setRows] = useState([]);

    useEffect(() => {
        apiGet("/novidades").then((response) => {
          if (response && response.data) {
            setRows(response.data);
          }
        });
      }, []);

    const columns = [
        {
          field: "image",
          headerName: "Imagem",
          width: 100,
          renderCell: (params) => <img height="40" src={`${apiURL}/novidades/banner/${params.value}`} alt="" />,
        },
        { field: "title", headerName: "Título", width: 250 },
        { field: "text", headerName: "Texto", width: 300 },
        { field: "order", headerName: "Ordem", width: 130 },
      ];

    return (<div style={{ height: 550, width: "100%" }}>
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      checkboxSelection
      disableMultipleSelection={true}
      onSelectionModelChange={params.handleCellSelected}
    />
  </div>);
}

export default GridNovidades;