import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";


import FormBtnSubmit from "../../components/FormBtnSubmit";
import FormBtnVoltar from "../../components/FormBtnVoltar";
import MessageSnackbar from "../../components/MessageSnackbar";

import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import "moment/locale/pt-br";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import BtnLimparUpload from "../../components/BtnLimparUpload";
import BtnHBLOpenPDF from "../../components/BtnHBLOpenPDF";
import BtnRemoverArquivo from "../../components/BtnRemoverArquivo";

import { apiGet, apiPut, apiPost } from "../../api";

const FormReservas = (params) => {
  const [submitButtonsDisabled, setSubmitButtonsDisabled] = useState(false);
  const [empresa, setEmpresa] = useState(0);
  const [empresasList, setEmpresasList] = useState([]);
  const [kingRef, setKingRef] = useState("");
  const [costumerRef, setCostumerRef] = useState("");
  const [dataCriacao, setDataCriacao] = useState(moment());
  const [ets, setEts] = useState(moment());
  const [eta, setEta] = useState(moment());
  const [hbl, setHbl] = useState("");
  const [hblArquivo, setHblArquivo] = useState([]);
  const [hblArquivoKey, setHblArquivoKey] = useState("");
  const [origem, setOrigem] = useState("");
  const [destino, setDestino] = useState("");
  const [container, setContainer] = useState("");
  const [isNome, setIsNome] = useState("");
  const [isEmail, setIsEmail] = useState("");
  const [navioNome, setNavioNome] = useState("");
  const [status, setStatus] = useState("Em andamento");
  const [rastreamento, setRastreamento] = useState(false);
  const [importadorNome, setImportadorNome] = useState("");
  const [importadorDocumento, setImportadorDocumento] = useState("");
  const [feedBackMessage, setFeedBackMessage] = useState("");
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  function hblArquivoName() {
    if (hblArquivo.length === 0) {
      return "";
    }
    return hblArquivo[0].name;
  }

  useEffect(() => {
    if (params.id) {
      apiGet(`/reservas/${params.id}`).then((response) => {
        if (response && response.data) {
          let obj = response.data;
          setKingRef(obj.king_ref === null ? "" : obj.king_ref);
          setCostumerRef(obj.costumer_ref === null ? "" : obj.costumer_ref);
          setDataCriacao(obj.dt_criacao_reserva === null ? "" : obj.dt_criacao_reserva);
          setEts(obj.ets === null ? "" : obj.ets);
          setEta(obj.eta === null ? "" : obj.eta);
          setHbl(obj.hbl === null ? "" : obj.hbl);
          setHblArquivoKey(obj.hbl_arquivo === null ? "" : obj.hbl_arquivo);
          setOrigem(obj.origem === null ? "" : obj.origem);
          setDestino(obj.destino === null ? "" : obj.destino);
          setContainer(obj.container === null ? "" : obj.container);
          setIsNome(obj.is_name === null ? "" : obj.is_name);
          setIsEmail(obj.is_email === null ? "" : obj.is_email);
          setStatus(obj.status);
          setRastreamento(obj.rastreamento === null ? false : obj.rastreamento);
          setEmpresa(obj.empresa_id);
          setNavioNome(obj.navio_nome);
          setImportadorNome(obj.importador_nome);
          setImportadorDocumento(obj.importador_documento);
        }
        
      });
    }
    apiGet(`/empresas`).then((response) => {
      if (response && response.data) {
        setEmpresasList(response.data);
      }
    });
  }, [params]);

  const hideMessageSnackbar = () => {
    setFeedbackOpen(false);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleEmpresaChange = (e) => {
    setEmpresa(parseInt(e.target.value));
  };

  const handleChangeETS = (e) => {
    setEts(e);
  };

  const handleChangeETA = (e) => {
    setEta(e);
  };

  const handleChangeDataCriacao = (e) => 
  {
    setDataCriacao(e);
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    // let obj = {
    //   king_ref: kingRef,
    //   costumer_ref: costumerRef,
    //   ets: moment(ets).format("YYYY-MM-DD"),
    //   eta: moment(eta).format("YYYY-MM-DD"),
    //   hbl: hbl,
    //   origem: origem,
    //   destino: destino,
    //   container: container,
    //   is_name: isNome,
    //   is_email: isEmail,
    //   status: status,
    //   rastreamento: rastreamento,
    //   navio_nome: navioNome,
    // };

    const formData = new FormData();
    formData.append("king_ref", kingRef);
    formData.append("costumer_ref", costumerRef);
    formData.append("dt_criacao_reserva", moment(dataCriacao).format("YYYY-MM-DD"));
    formData.append("ets", moment(ets).format("YYYY-MM-DD"));
    formData.append("eta", moment(eta).format("YYYY-MM-DD"));
    formData.append("hbl", hbl);
    formData.append("origem", origem);
    formData.append("destino", destino);
    formData.append("container", container);
    formData.append("is_name", isNome);
    formData.append("is_email", isEmail);
    formData.append("status", status);
    formData.append("rastreamento", rastreamento);
    formData.append("navio_nome", navioNome);
    formData.append("importador_nome", importadorNome);
    formData.append("importador_documento", importadorDocumento);
  

    if (empresa !== 0) {
      // obj["empresa_id"] = empresa;
      formData.append("empresa_id", empresa);
    }

    if (hblArquivo.length > 0) {
      formData.append("hblArquivo", hblArquivo[0]);
    }

    const saveError = (error) => {
      setFeedBackMessage(error.response.data.mensagem);
    };

    setSubmitButtonsDisabled(true);

    if (params.id) {
      apiPut(`/reservas/${params.id}`, formData, saveError, true).then(
        (response) => {
          setFeedbackOpen(true);
          setSubmitButtonsDisabled(false);
          if (response && response.status === 200) {
            setFeedBackMessage("Salvo com sucesso");
            setTimeout(function () {
              window.location.href = params.listURL;
            }, 1000);
          }
        }
      );
    } else {
      apiPost("/reservas", formData, saveError, true).then((response) => {
        setFeedbackOpen(true);
        setSubmitButtonsDisabled(false);
        if (response && response.status === 200) {
          setFeedBackMessage("Salvo com sucesso");
          setTimeout(function () {
            window.location.href = params.listURL;
          }, 1000);
        }
      });
    }
  };

  return (
    <form method="post" action="" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Referência"
            variant="outlined"
            value={kingRef}
            onChange={(e) => setKingRef(e.target.value)}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Referência do cliente"
            variant="outlined"
            value={costumerRef}
            onChange={(e) => setCostumerRef(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
      <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={"pt-br"}>
            <DatePicker
              label="Data criação da reserva"
              value={dataCriacao}
              error={false}
              onChange={handleChangeDataCriacao}
              renderInput={(params) => (
                <TextField sx={{ mb: 2 }} fullWidth {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="select-empresa-label">Empresa</InputLabel>
            <Select
              labelId="select-empresa-label"
              id="select-empresa"
              value={empresa}
              label="Empresa"
              displayEmpty={true}
              onChange={handleEmpresaChange}
            >
              <MenuItem key={0} value="0">
                Nenhuma
              </MenuItem>
              {empresasList.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {el.nome}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel id="status-radio-label">Status</FormLabel>
            <RadioGroup row aria-labelledby="status-radio-label">
              <FormControlLabel
                value="Em andamento"
                onChange={handleStatusChange}
                checked={status === "Em andamento"}
                control={<Radio />}
                label="Em andamento"
              />
              <FormControlLabel
                value="Encerrado"
                onChange={handleStatusChange}
                checked={status === "Encerrado"}
                control={<Radio />}
                label="Encerrado"
              />
              <FormControlLabel
                value="Cancelado"
                onChange={handleStatusChange}
                checked={status === "Cancelado"}
                control={<Radio />}
                label="Cancelado"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormGroup sx={{ pt: 3, ml: 1}}>
            <FormControlLabel
              control={
                <Switch
                  checked={rastreamento}
                  onChange={(e) => setRastreamento(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Rastreamento"
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={"pt-br"}>
            <DatePicker
              label="ETS"
              value={ets}
              onChange={handleChangeETS}
              renderInput={(params) => (
                <TextField sx={{ mb: 2 }} fullWidth {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={"pt-br"}>
            <DatePicker
              label="ETA"
              value={eta}
              error={false}
              onChange={handleChangeETA}
              renderInput={(params) => (
                <TextField sx={{ mb: 2 }} fullWidth {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

    

      <Grid container spacing={2}>
      <Grid item xs={6}>
          <TextField
            label="Container"
            variant="outlined"
            value={container}
            onChange={(e) => setContainer(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
        

        <Grid item xs={6}>
          <TextField
            label="Navio"
            variant="outlined"
            value={navioNome}
            onChange={(e) => setNavioNome(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Origem"
            variant="outlined"
            value={origem}
            onChange={(e) => setOrigem(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Destino"
            variant="outlined"
            value={destino}
            onChange={(e) => setDestino(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="IS Nome"
            variant="outlined"
            value={isNome}
            onChange={(e) => setIsNome(e.target.value)}
            fullWidth
            sx={{ mb: 3 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="IS E-mail"
            variant="outlined"
            value={isEmail}
            onChange={(e) => setIsEmail(e.target.value)}
            fullWidth
            type="email"
            sx={{ mb: 2 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Importador Nome"
            variant="outlined"
            value={importadorNome}
            onChange={(e) => setImportadorNome(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Importador Documento"
            variant="outlined"
            value={importadorDocumento}
            onChange={(e) => setImportadorDocumento(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="HBL"
            variant="outlined"
            value={hbl}
            onChange={(e) => setHbl(e.target.value)}
            fullWidth
            sx={{ mb: 3 }}
          />
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={2}>
            <label htmlFor="contained-button-file">
              <Button variant="contained" component="span">
                Enviar PDF HBL
              </Button>
              <Input
                accept="application/pdf"
                id="contained-button-file"
                multiple
                type="file"
                name="image"
                onChange={(e) => setHblArquivo(e.target.files)}
                sx={{ display: "none" }}
              />
            </label>
            <Typography sx={{ pt: 1 }}>{hblArquivoName()}</Typography>
            <BtnLimparUpload file={hblArquivo} setFile={setHblArquivo} />
          </Stack>
          <Stack direction="row" spacing={2}>
            <BtnHBLOpenPDF arquivoKey={hblArquivoKey} hbl={hbl} />
            
          </Stack>
          <Stack direction="row" spacing={2}>
          <BtnRemoverArquivo fileKey={hblArquivoKey} deleteURI={`/reservas/hbl-pdf/${params.id}/${hblArquivoKey}`} />
          </Stack>
        </Grid>
      </Grid>

      <FormBtnSubmit submitButtonsDisabled={submitButtonsDisabled} />
      <FormBtnVoltar href={params.listURL} />
      <MessageSnackbar
        feedBackMessage={feedBackMessage}
        feedbackOpen={feedbackOpen}
        hideMessageSnackbar={hideMessageSnackbar}
      />
    </form>
  );
};

export default FormReservas;
