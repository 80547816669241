import React from 'react';

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


const MessageSnackbar = (params) => {


    function handleFeedbackClose()
    {
      params.hideMessageSnackbar();
    }

    const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleFeedbackClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );


    return (<Snackbar
        open={params.feedbackOpen}
        autoHideDuration={6000}
        onClose={handleFeedbackClose}
        message={params.feedBackMessage}
        action={action}
      />)
}   

export default MessageSnackbar;

