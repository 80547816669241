import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import FormBtnSubmit from "../../components/FormBtnSubmit";
import FormBtnVoltar from "../../components/FormBtnVoltar";
import MessageSnackbar from "../../components/MessageSnackbar";
import { apiGet, apiPut, apiPost } from "../../api";

const FormEmpresas = (params) => {
  const [submitButtonsDisabled, setSubmitButtonsDisabled] = useState(false);
  const [nome, setNome] = useState("");
  const [feedBackMessage, setFeedBackMessage] = useState("");
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  useEffect(() => {
    if (params.id) {
      apiGet(`/empresas/${params.id}`).then((response) => {
        if (response && response.data) {
          console.log(response.data);
          let obj = response.data;
          setNome(obj.nome);
        }
      });
    }
  }, [params]);

  const hideMessageSnackbar = () => {
    setFeedbackOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const obj = {
      nome: nome
    };

    const saveError = (error) => {
      setFeedBackMessage(error.response.data.mensagem);
    };

    setSubmitButtonsDisabled(true);

    if (params.id) {
      apiPut(`/empresas/${params.id}`, obj, saveError).then((response) => {
        setFeedbackOpen(true);
        setSubmitButtonsDisabled(false);
        if (response && response.status === 200) {
          setFeedBackMessage("Salvo com sucesso");
          setTimeout(function () {
            window.location.href = params.listURL;
          }, 1000);
        }
      });
    } else {
      apiPost("/empresas", obj, saveError).then((response) => {
        setFeedbackOpen(true);
        setSubmitButtonsDisabled(false);
        if (response && response.status === 200) {
          setFeedBackMessage("Salvo com sucesso");
          setTimeout(function () {
            window.location.href = params.listURL;
          }, 1000);
        }
      });
    }
  };

  return (
    <form method="post" action="" onSubmit={handleSubmit}>
      <TextField
        label="Nome"
        variant="outlined"
        value={nome}
        onChange={(e) => setNome(e.target.value)}
        fullWidth
        required
        sx={{ mb: 2 }}
      />
      <FormBtnSubmit submitButtonsDisabled={submitButtonsDisabled} />
      <FormBtnVoltar href={params.listURL} />
      <MessageSnackbar feedBackMessage={feedBackMessage} feedbackOpen={feedbackOpen} hideMessageSnackbar={hideMessageSnackbar} />
    </form>
  );
};

export default FormEmpresas;
