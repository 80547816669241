import React from "react";

import Add from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const GridBtnAdd = (params) => {
  return (
    <Link to={params.href} style={{ textDecoration: "none" }}>
      <Button variant="outlined" startIcon={<Add />}>
        Adicionar
      </Button>
    </Link>
  );
};

export default GridBtnAdd;
