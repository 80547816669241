import React from "react";

import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";

const GridBtnEditar = (params) => {
  const btnEditarClick = () => {
    // window.location.href = `/novidades/form/${rowSelected}`;
    window.location.href = params.href;
    return;
  };

  return (
    <Button
    variant="outlined"
    sx={{ ml: 2 }}
    disabled={params.btnActionsDisabled}
    onClick={btnEditarClick}
    startIcon={<Edit />}
  >
    Editar
  </Button>
  );
};

export default GridBtnEditar;
