import React, { useState } from "react";
import { apiPost } from "../../api";
import ReCAPTCHA from "react-google-recaptcha";

import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import Typography from "@mui/material/Typography";
import brand from "../../assets/img/brand.svg";

function PageAuth() {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [fieldSenhaErro, setFieldSenhaErro] = useState(false);
  const [fieldSenhaErroHelp, setFieldSenhaErroHelp] = useState("");
  const [btnEntrarDisabled, setBtnEntrarDisabled] = useState(false);
  const [loginResultMessage, setLoginResultMessage] = useState();
  const [loginResultDisplay, setLoginResultDisplay] = useState("none");
  const RECAPTCHA_SITE_KEY = "6LcrrzwfAAAAAMCQrihnCul4oCakim_8nN0-XiL4";
  const recaptchaRef = React.createRef();

  async function handleSubmit(e) {
    e.preventDefault();

    if (!senhaActiveValidation()) {
      return;
    }

    setBtnEntrarDisabled(true);

    const token = await recaptchaRef.current.executeAsync();

    apiPost(`google-recaptcha-validate`, { token: token }).then((response) => {
      if (response && response.status === 200) {
        apiPost("/usuarios/verify-admin-pass", {
          email: email,
          pass: senha,
        }).then((response) => {
          setBtnEntrarDisabled(false);
          setLoginResultDisplay("none");
          setLoginResultMessage("");
          if (response && response.status === 200) {

            if( response.data.message === "pass_temp" )
            {
              window.location.href = "/pass-temp";
            }
            else 
            {
              window.localStorage.setItem("token", response.data.token);
              window.localStorage.setItem("userEmail", response.data.user_email);
              window.location.href = "/";
            }

            
          } else {
            setLoginResultDisplay("");
            setLoginResultMessage("Login/senha incorretos ou usuário desativado");
          }
        });
      }
      else 
      {
        setLoginResultDisplay("");
        setLoginResultMessage("Validação de segurança antibot bloqueou essa requisição.");
      }
    });

    

  }

  function senhaActiveValidation() {
    if (senha.length < 4 || senha.length > 20) {
      setFieldSenhaErro(true);
      setFieldSenhaErroHelp("Senha deve ter entre 4 e 20 caracteres");
      return false;
    } else {
      setFieldSenhaErro(false);
      setFieldSenhaErroHelp("");
      return true;
    }
  }

  return (
    <Container maxWidth="sm">
      <Paper variant="outlined" sx={{ p: 3, mt: 5 }}>
        <Box sx={{ textAlign: "center" }}>
          <img src={brand} width="150" alt="Kingshipline" />
        </Box>
        <Typography
          variant="h6"
          component="h1"
          sx={{ textAlign: "center", mb: 2, mt: 1, color: "primary.main" }}
        >
          HUB Admin
        </Typography>
        <Alert severity="error" sx={{ mb: 2, display: loginResultDisplay }}>{loginResultMessage}</Alert>

        <form action="" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            required
            id="email"
            label="E-mail"
            type="email"
            value={email}
            autoFocus={true}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            required
            type="password"
            id="senha"
            label="Senha"
            sx={{ mt: 2 }}
            error={fieldSenhaErro}
            helperText={fieldSenhaErroHelp}
            value={senha}
            onChange={(e) => {
              setSenha(e.target.value);
            }}
            onKeyUp={senhaActiveValidation}
          />
          <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_SITE_KEY}
            />
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
            disabled={btnEntrarDisabled}
          >
            Entrar
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default PageAuth;
