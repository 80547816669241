import React from "react";

import Delete from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";

import { apiDelete } from "../../api";

const GridBtnDeletar = (params) => {

  const btnDeletarClick = () => {
    if (window.confirm("Deseja realmente excluir?")) {
      apiDelete(params.href).then((response) => {
        if (response && response.status === 200) {
          window.location.reload();
        } else {
          alert("falha ao excluir");
        }
      });
    }
    return;
  };

  return (
    <Button
      variant="outlined"
      sx={{ ml: 2 }}
      disabled={params.btnActionsDisabled}
      color="error"
      onClick={btnDeletarClick}
      startIcon={<Delete />}
    >
      Deletar
    </Button>
  );
};

export default GridBtnDeletar;
