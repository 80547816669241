import React from "react";

import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import CampaignIcon from "@mui/icons-material/Campaign";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import WorkIcon from "@mui/icons-material/Work";
import GroupIcon from "@mui/icons-material/Group";

import { Link } from "react-router-dom";

const Menu = (props) => {
  return (
    <MenuList>
      <Link to="/novidades" style={{ textDecoration: "none", color: "#222" }}>
        <MenuItem selected={props.active === "novidades"}>
          <ListItemIcon>
            <CampaignIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Novidades</ListItemText>
        </MenuItem>
      </Link>
      <Link to="/reservas" style={{ textDecoration: "none", color: "#222" }}>
        <MenuItem selected={props.active === "reservas"}>
          <ListItemIcon>
            <DirectionsBoatIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Reservas</ListItemText>
        </MenuItem>
      </Link>
      <Link to="/empresas" style={{ textDecoration: "none", color: "#222" }}>
        <MenuItem selected={props.active === "empresas"}>
          <ListItemIcon>
            <WorkIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Empresas</ListItemText>
        </MenuItem>
      </Link>
      <Link to="/usuarios" style={{ textDecoration: "none", color: "#222" }}>
        <MenuItem selected={props.active === "usuarios"}>
          <ListItemIcon>
            <GroupIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Usuários</ListItemText>
        </MenuItem>
      </Link>
    </MenuList>
  );
};

export default Menu;
