import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";

import FormBtnSubmit from "../../components/FormBtnSubmit";
import FormBtnVoltar from "../../components/FormBtnVoltar";
import MessageSnackbar from "../../components/MessageSnackbar";
import { apiGet, apiPut, apiPost } from "../../api";

const FormUsuarios = (params) => {
  const [submitButtonsDisabled, setSubmitButtonsDisabled] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [empresaSugerida, setEmpresaSugerida] = useState("");
  const [empresa, setEmpresa] = useState(0);
  const [empresaList, setEmpresasList] = useState([]);
  const [role, setRole] = useState("user");
  const [status, setStatus] = useState("active");
  const [feedBackMessage, setFeedBackMessage] = useState("");
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  useEffect(() => {
    if (params.id) {
      apiGet(`/usuarios/${params.id}`).then((response) => {
        if (response && response.data) {
          let obj = response.data;
          setNome(obj.name);
          setEmail(obj.email);
          setEmpresaSugerida(
            `Empresa sugerida: ${
              obj.empresa_suggestion === null
                ? "nenhuma"
                : obj.empresa_suggestion
            }`
          );
          setEmpresa(obj.empresa_id);
          setRole(obj.role);
          setStatus(obj.status);
          setPhone(obj.phone);
        }
      });
    }

    apiGet(`/empresas`).then((response) => {
      if (response && response.data) {
        setEmpresasList(response.data);
      }
    });
  }, [params]);

  const hideMessageSnackbar = () => {
    setFeedbackOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const obj = {
      name: nome,
      email: email,
      phone: phone,
      status: status,
      role: role,
    };

    if (empresa !== 0) {
      obj["empresa_id"] = empresa;
    }

    if( role === "admin" && params.id === undefined )
    {
      obj["pass"] = "kingshipline";
      obj["pass_temp"] = true;
    }

    const saveError = (error) => {
      setFeedBackMessage(error.response.data.mensagem);
    };

    setSubmitButtonsDisabled(true);

    if (params.id) {
      apiPut(`/usuarios/${params.id}`, obj, saveError).then((response) => {
        setFeedbackOpen(true);
        setSubmitButtonsDisabled(false);
        if (response && response.status === 200) {
          setFeedBackMessage("Salvo com sucesso");
          setTimeout(function () {
            window.location.href = params.listURL;
          }, 1000);
        }
      });
    } else {
      apiPost("/usuarios", obj, saveError).then((response) => {
        setFeedbackOpen(true);
        setSubmitButtonsDisabled(false);
        if (response && response.status === 200) {
          setFeedBackMessage("Salvo com sucesso");
          setTimeout(function () {
            window.location.href = params.listURL;
          }, 1000);
        }
      });
    }
  };

  const handleEmpresaChange = (e) => {
    setEmpresa(parseInt(e.target.value));
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };


  return (
    <form method="post" action="" onSubmit={handleSubmit}>
      <TextField
        label="Nome"
        variant="outlined"
        value={nome}
        onChange={(e) => setNome(e.target.value)}
        fullWidth
        required
        sx={{ mb: 2 }}
      />
      <TextField
        label="E-mail"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        required
        sx={{ mb: 2 }}
      />
      <TextField
        label="Telefone"
        variant="outlined"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        fullWidth
        sx={{ mb: 3 }}
      />


      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="select-empresa-label">Empresa</InputLabel>
        <Select
          labelId="select-empresa-label"
          id="select-empresa"
          value={empresa}
          label="Empresa"
          displayEmpty={true}
          onChange={handleEmpresaChange}
        >
          <MenuItem key={0} value="0">
            Nenhuma
          </MenuItem>
          {empresaList.map((el) => {
            return (
              <MenuItem key={el.id} value={el.id}>
                {el.nome}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{empresaSugerida}</FormHelperText>
      </FormControl>

      <FormControl fullWidth>
        <FormLabel id="role-radio-label">Perfil</FormLabel>
        <RadioGroup row aria-labelledby="role-radio-label">
          <FormControlLabel
            value="user"
            onChange={handleRoleChange}
            checked={role === "user"}
            control={<Radio />}
            label="User"
          />
          <FormControlLabel
            value="admin"
            onChange={handleRoleChange}
            checked={role === "admin"}
            control={<Radio />}
            label="Admin"
          />
        </RadioGroup>
      </FormControl>

      <AlertSenhaProvisoria role={role} isCreate={ params.id === undefined } />


      <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
        <FormLabel id="status-radio-label">Status</FormLabel>
        <RadioGroup row aria-labelledby="status-radio-label">
          <FormControlLabel
            value="active"
            onChange={handleStatusChange}
            checked={status === "active"}
            control={<Radio />}
            label="active"
          />
          <FormControlLabel
            value="requested_access"
            onChange={handleStatusChange}
            checked={status === "requested_access"}
            control={<Radio />}
            label="requested_access"
          />
        </RadioGroup>
      </FormControl>

      <FormBtnSubmit submitButtonsDisabled={submitButtonsDisabled} />
      <FormBtnVoltar href={params.listURL} />
      <MessageSnackbar
        feedBackMessage={feedBackMessage}
        feedbackOpen={feedbackOpen}
        hideMessageSnackbar={hideMessageSnackbar}
      />
    </form>
  );
};

const AlertSenhaProvisoria = (params) => 
{
  if( params.role === "admin" && params.isCreate )
  {
    return (<Alert severity="warning">Senha provisoria: kingshipline</Alert>);
  }
  return null;
}

export default FormUsuarios;
