import React from 'react';

import Button from "@mui/material/Button";

const FormBtnSubmit = (params) => 
{
    return (<Button
        variant="contained"
        disabled={params.submitButtonsDisabled}
        type="submit"
        sx={{ mr: 2 }}
      >
        Salvar
      </Button>)
}

export default FormBtnSubmit;