import React from "react";

import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userEmail = window.localStorage.userEmail;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    if (e.target.id === "header-menu-sair") {
      logout();
    }
    if (e.target.id === "header-menu-alterar-senha") {
      window.location.href = "/alterar-senha";
    }
    setAnchorEl(null);
  };

  const logout = () => {
    if (window.confirm("Deseja realmente sair do sistema?")) {
      window.localStorage.removeItem("token");
      window.location.href = "/auth";
    }
  };

  return (
    <AppBar position="static">
      <Container maxWidth="lg">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            HUB Admin
          </Typography>

          <Typography component="span" sx={{ mr: 2 }}> 
            {userEmail}
          </Typography>

          <div>
         

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem id="header-menu-alterar-senha" onClick={handleClose}>
                Alterar Senha
              </MenuItem>
              <MenuItem id="header-menu-sair" onClick={handleClose}>
                Sair
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
