import React, { useState } from "react";

import Header from "../../components/Header";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Menu from "../../components/Menu";

import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";

import GridReservas from "../../components/GridReservas";
import GridBtnEditar from "../../components/GridBtnEditar";
import GridBtnAdd from "../../components/GridBtnAdd";
import GridBtnDeletar from "../../components/GridBtnDeletar";

const PageReservas = () => {
  const [btnActionsDisabled, setBtnActionsDisabled] = useState(true);
  const [rowSelected, setRowSelected] = useState(0);

  const handleCellSelected = (newSelection) => {
    setBtnActionsDisabled(newSelection.length !== 1);
    setRowSelected(newSelection.length === 0 ? 0 : newSelection[0]);
  };

  return (
    <React.Fragment>
      <Header />
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Menu active="reservas" />
          </Grid>
          <Grid item xs={9} sx={{ mt: 1 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Reservas
            </Typography>
            <Box sx={{ mb: 2 }}>
              <GridBtnAdd href="/reservas/form" />
              <GridBtnEditar
                href={`/reservas/form/${rowSelected}`}
                btnActionsDisabled={btnActionsDisabled}
              />
              <GridBtnDeletar
                href={`/reservas/${rowSelected}`}
                btnActionsDisabled={btnActionsDisabled}
              />
            </Box>
            <GridReservas handleCellSelected={handleCellSelected} />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default PageReservas;
