import React, { useEffect, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { apiGet } from "../../api";

const GridReservas = (params) => { 

    const [rows, setRows] = useState([]);

    useEffect(() => {
        apiGet("/reservas").then((response) => {
          if (response && response.data) {
            response.data.forEach( (el,i) => {
              response.data[i]["empresa_nome"] = el.Empresa.nome;
            })
            setRows(response.data);
          }
        });
      }, []);

    const columns = [
        { field: "king_ref", headerName: "Ref.", width: 100},
        { field: "costumer_ref", headerName: "Ref. Cliente", width: 150},
        { field: "origem", headerName: "Origem", width: 100},
        { field: "hbl", headerName: "HBL", width: 100},
        { field: "empresa_nome", headerName: "Empresa", width: 150},
        { field: "status", headerName: "Status", width: 150},
      ];

    return (<div style={{ height: 550, width: "100%" }}>
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      checkboxSelection
      disableMultipleSelection={true}
      onSelectionModelChange={params.handleCellSelected}
    />
  </div>);
}

export default GridReservas;