import React from "react";
import PDFIcon from "@mui/icons-material/InsertDriveFile";
import Button from "@mui/material/Button";

import apiURL from "../../apiURL";

function BtnHBLOpenPDF(params) {
  if (params.arquivoKey === "" || params.arquivoKey === null) {
    return "";
  }
  function handleHBLPDFClick() {
    window.open(`${apiURL}/reservas/hbl-pdf/${params.arquivoKey}`);
  }

  function fileName() {
    if (params.hbl !== "") {
      return `${params.hbl}.pdf`;
    }
    return "hbl.pdf";
  }

  return (
    <Button
      sx={{ mt: 1 }}
      size="small"
      variant="outlined"
      onClick={handleHBLPDFClick}
      startIcon={<PDFIcon />}
    >
      {fileName()}
    </Button>
  );
}

export default BtnHBLOpenPDF;
