import React from "react";
import { Route, Routes } from "react-router-dom";
import PageAuth from "./routes/auth";
import PageHome from "./routes/home";
import PageNovidades from "./routes/novidades";
import PageNovidadesForm from "./routes/novidades-form";
import PageEmpresas from "./routes/empresas";
import PageEmpresasForm from "./routes/empresas-form";
import PageUsuarios from "./routes/usuarios";
import PageUsuariosForm from "./routes/usuarios-form";
import PageReservas from "./routes/reservas";
import PageReservasForm from "./routes/reservas-form";
import PageAlterarSenhaForm from "./routes/alterar-senha-form";
import PagePassTempForm from "./routes/pass-temp-form";

function App() {
  return (
    <Routes>
      <Route path="auth" element={<PageAuth />} />
      <Route path="/" element={<PageHome />} />
      <Route path="/alterar-senha" element={<PageAlterarSenhaForm />} />
      <Route path="/pass-temp" element={<PagePassTempForm />} />
      <Route path="/novidades" element={<PageNovidades />} />
      <Route path="/novidades/form" element={<PageNovidadesForm />} />
      <Route path="/novidades/form/:id" element={<PageNovidadesForm />} />
      <Route path="/empresas" element={<PageEmpresas />} />
      <Route path="/empresas/form" element={<PageEmpresasForm />} />
      <Route path="/empresas/form/:id" element={<PageEmpresasForm />} />
      <Route path="/usuarios" element={<PageUsuarios />} />
      <Route path="/usuarios/form" element={<PageUsuariosForm />} />
      <Route path="/usuarios/form/:id" element={<PageUsuariosForm />} />
      <Route path="/reservas" element={<PageReservas />} />
      <Route path="/reservas/form" element={<PageReservasForm />} />
      <Route path="/reservas/form/:id" element={<PageReservasForm />} />
    </Routes>
  );
}

export default App;
