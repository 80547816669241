import axios from "axios";
import apiURL from "./apiURL";


const token = window.localStorage.getItem("token");
const api = axios.create({
  baseURL: apiURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export async function apiGet(uri) {
  const response = await api.get(uri).catch((error) => {
    if (error.response) {
      if (error.response.status === 403) {
        window.location = "/auth";
        return;
      }
    }
  });
  return response;
}

export async function apiPost(uri, data, callback, uploadFile=false) {
  try {
    let headers = null;
    if( uploadFile )
    {
      headers = { 'Content-Type' : 'multipart/form-data' }
    }

    const response = await api
      .post(uri, data, headers)
      .catch((error) => {
        if (error.response) {
          const notAuthURI = [
            "/usuarios/verify-pass-by-token",
            "/usuarios/verify-admin-pass",
          ];

          if (error.response.status === 403 && notAuthURI.indexOf(uri) !== -1) {
            callback(error);
          } else if (error.response.status === 403) {
            window.location = "/auth";
            return;
          } else if (error.response.status === 400) {
            callback(error);
          }
        }
      });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function apiPut(uri, data, callback, uploadFile = false) {
  try {

    let headers = null;
    if( uploadFile )
    {
      headers = { 'Content-Type' : 'multipart/form-data' }
    }

    const response = await api
      .put(uri, data, headers)
      .catch((error) => {
        if (error.response) {
          const notAuthURI = ["/usuarios/update-temp-pass"];
          if (error.response.status === 403 && notAuthURI.indexOf(uri) !== -1) {
            callback(error);
          } else if (error.response.status === 403) {
            window.location = "/auth";
            return;
          } else if (error.response.status === 400) {
            callback(error);
          }
        }
      });
    return response;
  } catch (error) {
    return console.log(error);
  }
}

export async function apiDelete(uri) {
  const response = await api.delete(uri).catch((error) => {
    if (error.response) {
      if (error.response.status === 403) {
        window.location = "/auth";
        return;
      }
    }
  });
  return response;
}
