import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import FormBtnSubmit from "../../components/FormBtnSubmit";
import FormBtnVoltar from "../../components/FormBtnVoltar";
import MessageSnackbar from "../../components/MessageSnackbar";
import { apiPost, apiPut } from "../../api";

const FormAlterarSenha = () => {
  const [submitButtonsDisabled, setSubmitButtonsDisabled] = useState(false);
  const [pass, setPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPass2, setNeWPass2] = useState("");

  const [feedBackMessage, setFeedBackMessage] = useState("");
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const [fieldSenhaErro, setFieldSenhaErro] = useState(false);
  const [fieldSenhaErroHelp, setFieldSenhaErroHelp] = useState("");
  const [fieldNewPassErro, setFieldNewPassErro] = useState(false);
  const [fieldNewPassErroHelp, setFieldNewPassErroHelp] = useState("");
  const [fieldNewPass2Erro, setFieldNewPass2Erro] = useState(false);
  const [fieldNewPass2ErroHelp, setFieldNewPass2ErroHelp] = useState("");

  const hideMessageSnackbar = () => {
    setFeedbackOpen(false);
  };

  function senhaActiveValidation() {
    if (pass.length < 4 || pass.length > 20) {
      setFieldSenhaErro(true);
      setFieldSenhaErroHelp("Senha deve ter entre 4 e 20 caracteres");
      return false;
    } else {
      setFieldSenhaErro(false);
      setFieldSenhaErroHelp("");
      return true;
    }
  }

  function newPassActiveValidation() {
    if (pass.length < 4 || pass.length > 20) {
      setFieldNewPassErro(true);
      setFieldNewPassErroHelp("Senha deve ter entre 4 e 20 caracteres");
      return false;
    } else {
      setFieldNewPassErro(false);
      setFieldNewPassErroHelp("");
      return true;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!senhaActiveValidation() || !newPassActiveValidation()) {
      return;
    }

    const saveError = (error) => {
      setFeedbackOpen(true);
      setFeedBackMessage(error.response.data.mensagem);
    };

    const senhaIncorretaError = (error) => {
      setFeedbackOpen(true);
      setFeedBackMessage("Senha atual incorreta");
    };

    setFieldNewPass2Erro(false);
    setFieldNewPass2ErroHelp("");
    if (newPass !== newPass2) {
      setFieldNewPass2Erro(true);
      setFieldNewPass2ErroHelp("Nova senha foi repetida incorretamente");
      return;
    }

    const objVerificarSenha = {
      pass: pass,
    };

    const obj = {
      pass: newPass,
    };

    setSubmitButtonsDisabled(true);

    apiPost(
      `/usuarios/verify-pass-by-token`,
      objVerificarSenha,
      senhaIncorretaError
    ).then((response) => {
      if (response && response.status === 200) {
        apiPut(`/usuarios/update-by-token`, obj, saveError).then((response) => {
          setFeedbackOpen(true);
          setSubmitButtonsDisabled(false);
          if (response && response.status === 200) {
            setFeedBackMessage("Senha alterada com sucesso");
            setTimeout(function () {
              window.location.href = "/auth";
            }, 1000);
          }
        });
      } else {
        setSubmitButtonsDisabled(false);
      }
    });
  };

  return (
    <form method="post" action="" onSubmit={handleSubmit}>
      <TextField
        label="Senha atual"
        variant="outlined"
        value={pass}
        onChange={(e) => setPass(e.target.value)}
        fullWidth
        required
        type="password"
        error={fieldSenhaErro}
        helperText={fieldSenhaErroHelp}
        onKeyUp={senhaActiveValidation}
        sx={{ mb: 2 }}
      />

      <TextField
        label="Nova Senha"
        variant="outlined"
        value={newPass}
        onChange={(e) => setNewPass(e.target.value)}
        fullWidth
        required
        type="password"
        error={fieldNewPassErro}
        helperText={fieldNewPassErroHelp}
        onKeyUp={newPassActiveValidation}
        sx={{ mb: 2 }}
      />

      <TextField
        label="Repetir nova senha"
        variant="outlined"
        value={newPass2}
        onChange={(e) => setNeWPass2(e.target.value)}
        fullWidth
        required
        type="password"
        error={fieldNewPass2Erro}
        helperText={fieldNewPass2ErroHelp}
        sx={{ mb: 2 }}
      />
      <FormBtnSubmit submitButtonsDisabled={submitButtonsDisabled} />
      <FormBtnVoltar href="/" />
      <MessageSnackbar
        feedBackMessage={feedBackMessage}
        feedbackOpen={feedbackOpen}
        hideMessageSnackbar={hideMessageSnackbar}
      />
    </form>
  );
};

export default FormAlterarSenha;
