import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import FormBtnSubmit from "../FormBtnSubmit";
import BtnLimparUpload from "../../components/BtnLimparUpload";
import BtnRemoverArquivo from "../../components/BtnRemoverArquivo";
import FileImagePreview from "../../components/FileImagePreview";

import FormBtnVoltar from "../FormBtnVoltar";
import MessageSnackbar from "../MessageSnackbar";
import { apiGet, apiPut, apiPost } from "../../api";

const FormNovidades = (params) => {
  const [submitButtonsDisabled, setSubmitButtonsDisabled] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [texto, setTexto] = useState("");
  const [ordem, setOrdem] = useState(0);
  const [image, setImage] = useState([]);
  const [imageKey, setImageKey] = useState("");
  const [feedBackMessage, setFeedBackMessage] = useState("");
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  useEffect(() => {
    if (params.id) {
      apiGet(`/novidades/${params.id}`).then((response) => {
        if (response && response.data) {
          let obj = response.data;
          setTitulo(obj.title);
          setTexto(obj.text);
          setOrdem(obj.order);
          setImageKey(obj.image);
        }
      });
    }
  }, [params]);

  const hideMessageSnackbar = () => {
    setFeedbackOpen(false);
  };

  function imageName() {
    if (image.length === 0) {
      return "";
    }
    return image[0].name;
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", titulo);
    formData.append("text", texto);
    formData.append("order", ordem);

    if (image.length > 0) {
      formData.append("image", image[0]);
    }

    const saveError = (error) => {
      setFeedBackMessage(error.response.data.mensagem);
    };

    setSubmitButtonsDisabled(true);

    if (params.id) {
      apiPut(`/novidades/${params.id}`, formData, saveError, true).then(
        (response) => {
          setFeedbackOpen(true);
          setSubmitButtonsDisabled(false);
          if (response && response.status === 200) {
            setFeedBackMessage("Salvo com sucesso");
            setTimeout(function () {
              window.location.href = params.listURL;
            }, 1000);
          }
        }
      );
    } else {
      apiPost("/novidades", formData, saveError, true).then((response) => {
        setFeedbackOpen(true);
        setSubmitButtonsDisabled(false);
        if (response && response.status === 200) {
          setFeedBackMessage("Salvo com sucesso");
          setTimeout(function () {
            window.location.href = params.listURL;
          }, 1000);
        }
      });
    }
  };

  return (
    <form
      method="post"
      action=""
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <TextField
        label="Título"
        variant="outlined"
        value={titulo}
        onChange={(e) => setTitulo(e.target.value)}
        fullWidth
        required
        sx={{ mb: 2 }}
      />

      <Stack direction="row" spacing={2}>
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span">
            Enviar imagem
          </Button>
          <Input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            name="image"
            onChange={(e) => setImage(e.target.files)}
            sx={{ display: "none" }}
          />
        </label>
        <Typography sx={{ pt: 1 }}>{imageName()}</Typography>
        <BtnLimparUpload file={image} setFile={setImage} />
      </Stack>

      <Stack direction="row" spacing={2}>
        <FileImagePreview imageKey={imageKey} />
      </Stack>

      <BtnRemoverArquivo fileKey={imageKey} deleteURI={`/novidades/banner/${params.id}/${imageKey}`} />

      <TextField
        label="Texto"
        variant="outlined"
        value={texto}
        onChange={(e) => setTexto(e.target.value)}
        fullWidth
        multiline
        sx={{ mt: 3, mb: 2 }}
      />
      <TextField
        label="Ordem"
        variant="outlined"
        value={ordem}
        onChange={(e) => setOrdem(e.target.value)}
        fullWidth
        type="number"
        sx={{ mb: 2 }}
        required
      />
      <FormBtnSubmit submitButtonsDisabled={submitButtonsDisabled} />
      <FormBtnVoltar href={params.listURL} />
      <MessageSnackbar
        feedBackMessage={feedBackMessage}
        feedbackOpen={feedbackOpen}
        hideMessageSnackbar={hideMessageSnackbar}
      />
    </form>
  );
};

export default FormNovidades;
